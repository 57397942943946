import store from "../../app/store/store";
import {
  ApprovalIcon,
  AsideChevronIcon,
  ProjectIcon,
  ProposalIcon,
  RitualIcon,
  TimerIcon,
  UserIcon,
  ClientIcon,
  GraduationIcon
} from "../../app/components/IconsSVG";
import { ERoles } from "../../app/enums/Roles";
import { EMenus } from "../../app/enums/Menus";

export default () => {
  const { auth } = store.getState();

  const menu = {
    header: {
      self: {},
      items: []
    },
    aside: {
      self: {},
      items: []
    }
  };

  const idMenuItem = [];

  //"ADMIN, CONSULTANT, OPERATION, COMMERCIAL, FINANCIAL, RECRUITMENT"

  if (auth?.user && auth?.user?.role?.type === ERoles.ADMIN) {
    idMenuItem.push(
      EMenus.TIMER,
      EMenus.OPERACOES_APROVADOS,
      EMenus.OPERACOES_RELATORIO,
      EMenus.OPERACOES_FECHAMENTO,
      EMenus.OPERACOES_FECHAMENTO_FLEXIVEL,
      EMenus.OPERACOES_CONCILIACOES,
      EMenus.PROFISSIONAIS,
      EMenus.CLIENTE,
      EMenus.PROJETOS,
      EMenus.FABRICA,
      EMenus.RITUAIS,
      EMenus.FATURAMENTO_PROPOSTA,
      EMenus.FATURAMENTO_CHECK,
      EMenus.ADMINISTRATIVO_DATA_FECHAMENTO,
      EMenus.ADMINISTRATIVO_MENSAGEM_WHATSAPP,
      EMenus.ADMINISTRATIVO_FERIADOS,
      EMenus.ADMINISTRATIVO_NOTIFICACOES,
      EMenus.ADMINISTRATIVO_DATA_FECHAMENTO_FLEXIVEL,
      EMenus.TREINAMENTO,
      EMenus.TREINAMENTO_SUBGRUPO,
      EMenus.TREINAMENTO_GRUPO
    );
  }

  if (auth?.user && auth?.user?.role?.type === ERoles.CONSULTANT) {
    idMenuItem.push(
      EMenus.TIMER,
      EMenus.TREINAMENTO,
      EMenus.TREINAMENTO_SUBGRUPO,
      EMenus.TREINAMENTO_GRUPO
    );
  }

  if (auth?.user && auth?.user?.role?.type === ERoles.OPERATION) {
    idMenuItem.push(
      EMenus.TIMER,
      EMenus.OPERACOES_APROVADOS,
      EMenus.OPERACOES_RELATORIO,
      EMenus.OPERACOES_FECHAMENTO,
      EMenus.OPERACOES_FECHAMENTO_FLEXIVEL,
      EMenus.OPERACOES_CONCILIACOES,
      EMenus.PROFISSIONAIS,
      EMenus.CLIENTE,
      EMenus.PROJETOS,
      EMenus.RITUAIS,
      EMenus.FATURAMENTO_PROPOSTA,
      EMenus.FATURAMENTO_CHECK,
      EMenus.ADMINISTRATIVO_DATA_FECHAMENTO,
      EMenus.ADMINISTRATIVO_MENSAGEM_WHATSAPP,
      EMenus.ADMINISTRATIVO_FERIADOS,
      EMenus.ADMINISTRATIVO_NOTIFICACOES,
      EMenus.ADMINISTRATIVO_DATA_FECHAMENTO_FLEXIVEL,
      EMenus.TREINAMENTO,
      EMenus.TREINAMENTO_SUBGRUPO,
      EMenus.TREINAMENTO_GRUPO,
      EMenus.TREINAMENTO_GRUPO
    );
  }

  if (auth?.user && auth?.user?.role?.type === ERoles.COMMERCIAL) {
    idMenuItem.push(
      EMenus.TIMER,
      EMenus.OPERACOES_RELATORIO,
      EMenus.OPERACOES_FECHAMENTO,
      EMenus.OPERACOES_FECHAMENTO_FLEXIVEL,
      EMenus.OPERACOES_CONCILIACOES,
      EMenus.PROFISSIONAIS,
      EMenus.CLIENTE,
      EMenus.PROJETOS,
      EMenus.RITUAIS,
      EMenus.FATURAMENTO_PROPOSTA,
      EMenus.FATURAMENTO_CHECK,
      EMenus.ADMINISTRATIVO_NOTIFICACOES,
      EMenus.TREINAMENTO,
      EMenus.TREINAMENTO_SUBGRUPO,
      EMenus.TREINAMENTO_GRUPO
    );
  }

  if (auth?.user && auth?.user?.role?.type === ERoles.FINANCIAL) {
    idMenuItem.push(
      EMenus.TIMER,
      EMenus.OPERACOES_RELATORIO,
      EMenus.OPERACOES_FECHAMENTO,
      EMenus.OPERACOES_FECHAMENTO_FLEXIVEL,
      EMenus.OPERACOES_CONCILIACOES,
      EMenus.PROFISSIONAIS,
      EMenus.CLIENTE,
      EMenus.PROJETOS,
      EMenus.FATURAMENTO_PROPOSTA,
      EMenus.FATURAMENTO_CHECK,
      EMenus.TREINAMENTO,
      EMenus.TREINAMENTO_SUBGRUPO,
      EMenus.TREINAMENTO_GRUPO
    );
  }

  if (auth?.user && auth?.user?.role?.type === ERoles.RECRUITMENT) {
    idMenuItem.push(
      EMenus.TIMER, 
      EMenus.CLIENTE, 
      EMenus.PROFISSIONAIS,
      EMenus.TREINAMENTO,
      EMenus.TREINAMENTO_SUBGRUPO,
      EMenus.TREINAMENTO_GRUPO
    );
  }

  if (auth?.user && auth?.user?.role?.type === ERoles.FACTORY) {
    idMenuItem.push(
      EMenus.TIMER,
      EMenus.FABRICA,
      EMenus.TREINAMENTO,
      EMenus.TREINAMENTO_SUBGRUPO,
      EMenus.TREINAMENTO_GRUPO
    );
  }

  if (auth?.user && auth?.user?.role?.type === ERoles.CONSULTANTSIMPLIFIED) {
    idMenuItem.push(
      EMenus.TIMER,
      EMenus.TREINAMENTO,
      EMenus.TREINAMENTO_SUBGRUPO,
      EMenus.TREINAMENTO_GRUPO
    );
  }

  /* =============== */

  /* TIMER */

  if (idMenuItem.includes(EMenus.TIMER)) {
    menu.aside.items.push({
      title: "Timer",
      root: true,
      icon: TimerIcon,
      bullet: "none",
      page: "admin/timer"
    });
  }

  /* =============== */

  /* OPERAÇÕES */

  if (
    idMenuItem.includes(EMenus.OPERACOES_APROVADOS) ||
    idMenuItem.includes(EMenus.OPERACOES_RELATORIO) ||
    idMenuItem.includes(EMenus.OPERACOES_FECHAMENTO) ||
    idMenuItem.includes(EMenus.OPERACOES_FECHAMENTO_FLEXIVEL) ||
    idMenuItem.includes(EMenus.OPERACOES_CONCILIACOES)
  ) {
    menu.aside.items.push({
      title: "Operações",
      root: true,
      icon: ApprovalIcon,
      bullet: "none",
      submenu: [
        idMenuItem.includes(EMenus.OPERACOES_APROVADOS) &&{
          title: "Pendentes / Aprovados",
          icon: AsideChevronIcon,
          page: "admin/aprovacoes"
        },
        idMenuItem.includes(EMenus.OPERACOES_RELATORIO) && {
          title: "Relatórios",
          icon: AsideChevronIcon,
          page: "admin/relatorios"
        },
        idMenuItem.includes(EMenus.OPERACOES_FECHAMENTO) && {
          title: "Fechamento",
          icon: AsideChevronIcon,
          page: "admin/fechamento-ciclo"
        },
        idMenuItem.includes(EMenus.OPERACOES_FECHAMENTO_FLEXIVEL) && {
          title: "Fechamento Flexível",
          icon: AsideChevronIcon,
          page: "admin/fechamento-flexivel/projetos"
        },
        idMenuItem.includes(EMenus.OPERACOES_CONCILIACOES) && {
          title: "Conciliações",
          icon: AsideChevronIcon,
          page: "admin/conciliacoes"
        },
      ]
    });
  }

  /* =============== */

  /* PROFISSIONAIS */

  if (idMenuItem.includes(EMenus.PROFISSIONAIS)) {
    menu.aside.items.push({
      title: "Profissionais",
      root: true,
      icon: UserIcon,
      bullet: "none",
      page: "admin/profissionais"
    });
  }

  /* =============== */

  /* PROJETOS */

  if (idMenuItem.includes(EMenus.PROJETOS)) {
    menu.aside.items.push({
      title: "Gestão",
      root: true,
      icon: ProjectIcon,
      bullet: "none",
      submenu: [
        {
          title: "Clientes",
          icon: AsideChevronIcon,
          page: "admin/clientes"
        },
        {
          title: "Coordenações",
          icon: AsideChevronIcon,
          page: "admin/coordenacoes"
        },
        {
          title: "Áreas Pagadoras",
          icon: AsideChevronIcon,
          page: "admin/area-pagadora"
        },
        {
          title: "Tipos de Projeto",
          icon: AsideChevronIcon,
          page: "admin/tipos-de-projeto"
        },
        {
          title: "Projetos / Squads",
          icon: AsideChevronIcon,
          page: "admin/projetos"
        },
        {
          title: "Organograma",
          icon: AsideChevronIcon,
          page: "admin/organograma"
        }
      ]
    });
  }

  /* =============== */

    /* FÁBRICA */

    if (idMenuItem.includes(EMenus.FABRICA)) {
      menu.aside.items.push({
        title: "Fábrica",
        root: true,
        icon: ProjectIcon,
        bullet: "none",
        page: "admin/fabrica"
      });
    }

    /* =============== */

  /* RITUAIS */

  if (idMenuItem.includes(EMenus.RITUAIS)) {
    menu.aside.items.push({
      title: "Rituais",
      root: true,
      icon: RitualIcon,
      bullet: "none",
      page: "admin/rituais"
    });
  }

  /* =============== */

  /* FATURAMENTO */

  if (
    idMenuItem.includes(EMenus.FATURAMENTO_PROPOSTA) ||
    idMenuItem.includes(EMenus.FATURAMENTO_CHECK)
  ) {
    menu.aside.items.push({
      title: "Faturamento",
      root: true,
      icon: ProposalIcon,
      bullet: "none",
      submenu: [
        idMenuItem.includes(EMenus.FATURAMENTO_PROPOSTA) && {
          title: "Propostas",
          icon: AsideChevronIcon,
          page: "admin/propostas"
        },
        idMenuItem.includes(EMenus.FATURAMENTO_CHECK) && {
          title: "Check",
          icon: AsideChevronIcon,
          page: "admin/check"
        }
      ]
    });
  }

  /* =============== */

  /* ADMINISTRATIVO */

  if (
    idMenuItem.includes(EMenus.ADMINISTRATIVO_DATA_FECHAMENTO) ||
    idMenuItem.includes(EMenus.ADMINISTRATIVO_MENSAGEM_WHATSAPP) ||
    idMenuItem.includes(EMenus.ADMINISTRATIVO_FERIADOS) || 
    idMenuItem.includes(EMenus.ADMINISTRATIVO_NOTIFICACOES) ||
    idMenuItem.includes(EMenus.ADMINISTRATIVO_DATA_FECHAMENTO_FLEXIVEL)
  ) {
    menu.aside.items.push({
      title: "Administrativo",
      root: true,
      icon: ClientIcon,
      bullet: "none",
      submenu: [
        idMenuItem.includes(EMenus.ADMINISTRATIVO_DATA_FECHAMENTO) && {
          title: "Data de Fechamento",
          icon: AsideChevronIcon,
          page: "admin/data-de-fechamento"
        },
        idMenuItem.includes(EMenus.ADMINISTRATIVO_MENSAGEM_WHATSAPP) && {
          title: "Mensagem/Whatsapp",
          icon: AsideChevronIcon,
          page: "admin/mensagem-whatsapp"
        },
        idMenuItem.includes(EMenus.ADMINISTRATIVO_FERIADOS) && {
          title: "Feriados",
          icon: AsideChevronIcon,
          page: "admin/feriados"
        },
        idMenuItem.includes(EMenus.ADMINISTRATIVO_NOTIFICACOES) && {
          title: "Notificações",
          icon: AsideChevronIcon,
          page: "admin/notificacoes"
        },
        idMenuItem.includes(EMenus.ADMINISTRATIVO_DATA_FECHAMENTO_FLEXIVEL) && {
          title: "Data de Fechamento Flexível",
          icon: AsideChevronIcon,
          page: "admin/data-fechamento-flexivel"
        }
      ]
    });
  }

  /* =============== */
  /* TREINAMENTO */

  if (
    idMenuItem.includes(EMenus.TREINAMENTO) ||
    idMenuItem.includes(EMenus.TREINAMENTO_SUBGRUPO) ||
    idMenuItem.includes(EMenus.TREINAMENTO_GRUPO)
  ) {
    menu.aside.items.push({
      title: "Treinamento",
      root: true,
      icon: GraduationIcon,
      bullet: "none",
      submenu: [
        idMenuItem.includes(EMenus.TREINAMENTO) && {
          title: "Treinamentos",
          icon: AsideChevronIcon,
          page: "admin/treinamentos"
        },
        idMenuItem.includes(EMenus.TREINAMENTO_SUBGRUPO) && {
          title: "Subgrupos",
          icon: AsideChevronIcon,
          page: "admin/treinamento-subgrupos"
        },
        idMenuItem.includes(EMenus.TREINAMENTO_SUBGRUPO) && {
          title: "Grupos",
          icon: AsideChevronIcon,
          page: "admin/treinamento-grupos"
        }
      ]
    });
  }

  /* =============== */
  return menu;
};
