import React from "react";
import { Col, Form, Modal } from "react-bootstrap";
import { InputField } from "../../../../components/InputField";
import { StyledPlusIcon, StyledPasteIcon } from './style';
import { InexApiService } from "../../../../../../services/InexApiService";
import { Button, StyledModal, Fields, TitlePrj, Container, Period } from "./style";
import { calculateDurationDiff } from "../../../../../../utils/CalculateDurationDiff";
import { calculateSumTimes , transformMinutesToHours, transformHoursToMinutes} from "../../../../../../utils/CalculateSumTime";
import { GetMonthName, GetPeriodName } from "../../../../../../utils/GetYearsAndMonths";
import Swal from "sweetalert2";
import Loading from "../../../../../home/components/Loading";
import moment from 'moment';


export const DemandModal = ({ user, title, close, noteForBilling, projectList, setReload, startPeriod, endPeriod}) => {
  
  const [showModal, setShowModal] = React.useState(false);
  const [noteForBillingLocal, setNoteForBillingLocal] = React.useState(noteForBilling || "");
  const [loading, setLoading] = React.useState(false);

  const [projectListLocal, setProjectListLocal] = React.useState({});

  const api = new InexApiService();
  
  const loadLoadedMinutesByProject = async (projectListTmp) => {    
    for (const projectItem of projectListTmp) {
      let idx = 0;
      
      for (const loadedMinutesItem of projectItem.loadedMinutesList) {
        loadedMinutesItem.idx = idx;
        loadedMinutesItem.allocatedMinutesHoursFormat = transformMinutesToHours(loadedMinutesItem.allocatedMinutes); 
        loadedMinutesItem.loadedMinutesHoursFormat = transformMinutesToHours(loadedMinutesItem.loadedMinutes); 
        idx++;
      }
  
      // Adicionar item extra se totalLoadedMinutes < totalMinutes e se não houver um item em loadedMinutesList com idClientLoadedMinutes igual a null
      if ((projectItem.totalLoadedMinutes < projectItem.totalMinutes && !projectItem.loadedMinutesList.some(item => item.idClientLoadedMinutes === null))||projectItem.loadedMinutesList.length === 0 ) {
        const idxLast = idx ;
        projectItem.loadedMinutesList.push({
          demand: "",
          idClientLoadedMinutes: null,
          idx: idxLast,
          allocatedMinutes: 0,
          allocatedMinutesHoursFormat: "00:00:00",
          loadedMinutes: 0,
          loadedMinutesHoursFormat: "00:00:00",
          payingAreaName: projectItem.payingArea.name,
        });
      }
    }
    setProjectListLocal(projectListTmp)
  }

  React.useEffect(() => {

    if (projectListLocal.length > 0) {
      //Função apenas para log e debug
      //console.log(projectListLocal);
      //console.log(user);
    }
   
  }, [projectListLocal]); 

  React.useEffect(() => {
    if (user > 0){
      loadLoadedMinutesByProject(projectList);
    }
  }, [user]); 

  
  const handleAddFieldsInProject = async (idProject) => {  
    
    const projectListTmp = JSON.parse(JSON.stringify(projectListLocal));

    for (const projectItem of projectListTmp) {
      
      // Adicionar item extra se totalLoadedMinutes < totalMinutes e se não houver um item em loadedMinutesList com idClientLoadedMinutes igual a null
      if (projectItem.idProject === idProject) {

        const maxIdx = projectListLocal
                        .find(item => item.idProject === idProject)
                        .loadedMinutesList.reduce((acc, cur) => cur.idx > acc ? cur.idx : acc, 0);
        const newIdx = maxIdx + 1;
        projectItem.loadedMinutesList.push({
          demand: "",
          idClientLoadedMinutes: null,
          idx: newIdx,
          allocatedMinutes: 0,
          allocatedMinutesHoursFormat: "00:00:00",
          loadedMinutes: 0,
          loadedMinutesHoursFormat: "00:00:00",
          payingAreaName: projectItem.payingArea.name,
        });
      }
    }

    setProjectListLocal(projectListTmp)
  }

  const handleDemandChange = e => {
    const projectListTmp = [...projectListLocal];
    const arrTargetName = e.target.name.split(";;");
    const targetPrjId = parseInt(arrTargetName[1]);
    const idx = parseInt(arrTargetName[2]);
    const period = parseInt(arrTargetName[3]);

    let inputValue = e.target.value;
    for (const projectItem of projectListTmp) {
      if (projectItem.idProject === targetPrjId && projectItem.period === period){
        for (const loadedMinutesItem of projectItem.loadedMinutesList) {
          if (loadedMinutesItem.idx === idx){
            loadedMinutesItem.demand = inputValue.trim();
          }
        } 
      }
    }

    setProjectListLocal(projectListTmp);
    
  };

  const handleObservationChange = e => {

    if (e.target.value.length <= 100 ){
      setNoteForBillingLocal(e.target.value)
    }
   
  };

  const handlePayingAreaChange = e => {    
    const projectListTmp = [...projectListLocal]

    const arrTargetName = e.target.name.split(";;");
    const targetPrjId = parseInt(arrTargetName[1]);
    const idx = parseInt(arrTargetName[2]);
    const period = parseInt(arrTargetName[3]);

    let inputValue = e.target.value;
    for (const projectItem of projectListTmp) {
      if (projectItem.idProject === targetPrjId && projectItem.period === period){
        for (const loadedMinutesItem of projectItem.loadedMinutesList) {
          if (loadedMinutesItem.idx === idx){
            loadedMinutesItem.payingAreaName = inputValue.trim();
          }
        } 
      }
    }

    setProjectListLocal(projectListTmp); 

  };

  const handleAllocatedHoursChange = e => {
    const projectListTmp = [...projectListLocal]

    const arrTargetName = e.target.name.split(";;");
    const targetPrjId = parseInt(arrTargetName[1]);
    const idx = parseInt(arrTargetName[2]);
    const period = parseInt(arrTargetName[3]);

    let inputValue = e.target.value.replace(/[^\d:]/g, '').slice(0, 9);

    //Se o valor estiver vazio, atribuimos o horario zerado.
    if (inputValue === ''){
      inputValue = '00:00:00'
    }

    for (const projectItem of projectListTmp) {
      if (projectItem.idProject === targetPrjId && projectItem.period === period){
        for (const loadedMinutesItem of projectItem.loadedMinutesList) {
          if (loadedMinutesItem.idx === idx){
            loadedMinutesItem.allocatedMinutesHoursFormat = inputValue;
          }
        } 
        
      }
    }
 
    setProjectListLocal(projectListTmp); 
  };

  const handleLoadedHoursChange = e => {
    const projectListTmp = [...projectListLocal]

    const arrTargetName = e.target.name.split(";;");
    const targetPrjId = parseInt(arrTargetName[1]);
    const idx = parseInt(arrTargetName[2]);
    const period = parseInt(arrTargetName[3]);

    let inputValue = e.target.value.replace(/[^\d:]/g, '').slice(0, 9);

    //Se o valor estiver vazio, atribuimos o horario zerado.
    if (inputValue === ''){
      inputValue = '00:00:00'
    }

    for (const projectItem of projectListTmp) {
      if (projectItem.idProject === targetPrjId && projectItem.period === period){
        for (const loadedMinutesItem of projectItem.loadedMinutesList) {
          if (loadedMinutesItem.idx === idx){
            loadedMinutesItem.loadedMinutesHoursFormat = inputValue;
          }
        } 
        
      }
    }
 
    setProjectListLocal(projectListTmp); 
  };

  const handleModalConfirm = e => {
    // AQUI FOI PRECISO CRIAR UMA COPIA PROFUNDA (DEEPCOPY)
    // POIS LA EM BAIXO, SE TIVERMOS RETIRAMOS UM ITEM DA LISTA,
    // E FAZENDO UMA COPIA SIMPLES, ELE APAGA TB DA LISTA ORIGINAL projectListLocal
    // E NÃO QUEREMOS QUE ISSO ACONTEÇA. 
    const projectListTmp = JSON.parse(JSON.stringify(projectListLocal));


    let allRight = true;
    //Pattern para aceitar apenas hh:mm:ss onde hh pode ser até 999, mm pode ser no maximo 59, e segundos devem ser sempre 00. 
    let pattern = /^(?:[0-9]{1,4}\:)(?:[0-5][0-9]\:)(?:00)$/;

    
    outerloop:
    for (const projectItem of projectListTmp) {
      let totalAllocatedMinutes = 0;
      let totalLoadedMinutes = 0;
      const demandOccurrences = {};

      for (const loadedMinutesItem of projectItem.loadedMinutesList) {
        let result = pattern.test(loadedMinutesItem.loadedMinutesHoursFormat);

        if (!result) {
          Swal.fire(
            "Erro!",
            "Hora Jira digitada no projeto: " + projectItem.name + " - está no formato errado, deveria ser hh:mm:ss. ",
            "error"
          );
          allRight = false;
          break outerloop;
        }

        if (loadedMinutesItem.demand === '' && 
              (
                transformHoursToMinutes(loadedMinutesItem.loadedMinutesHoursFormat) > 0 || 
                transformHoursToMinutes(loadedMinutesItem.allocatedMinutesHoursFormat) > 0 || 
                loadedMinutesItem.idClientLoadedMinutes > 0
                )
            ){
          Swal.fire(
            "Erro!",
            "É necessário preencher o código da Demanda do projeto: " + projectItem.name,
            "error"
          );
          allRight = false;
          break outerloop;
        }

        if (loadedMinutesItem.demand) {
          if (demandOccurrences[loadedMinutesItem.demand]) {
            Swal.fire(
              "Erro!",
              `A demanda ${loadedMinutesItem.demand} já foi adicionada no projeto ${projectItem.name}`,
              "error"
            );
            allRight = false;
            break outerloop;
          } else {
            demandOccurrences[loadedMinutesItem.demand] = true;
          }
        }

        if (transformHoursToMinutes(loadedMinutesItem.loadedMinutesHoursFormat) > transformHoursToMinutes(loadedMinutesItem.allocatedMinutesHoursFormat)) {
          Swal.fire(
            "Erro!",
            "A Qtd de horas carregas é maior do que as horas alocadas do projeto/Demanda: " + projectItem.name + "/" + loadedMinutesItem.demand ,
            "error"
          );
          allRight = false;
          break outerloop;
        }

        // Se nada deu erro, vamos recuperar o valor do campo digitavel e jogar para dentro das variaveis de banco loadedMinutes , allocatedMinutes e totalMinutes
        loadedMinutesItem.allocatedMinutes = transformHoursToMinutes(loadedMinutesItem.allocatedMinutesHoursFormat);
        loadedMinutesItem.loadedMinutes = transformHoursToMinutes(loadedMinutesItem.loadedMinutesHoursFormat);
        
        
        //Se o registro que estamos lendo ainda não tem idClientLoadedMinutes (isso significa que ele não foi criado no banco ainda)
        // E se elestiver com loadedMinutes ou allocatedMinutes zerado, então excluimos o registro para não ir para o backend.
        if ((loadedMinutesItem.idClientLoadedMinutes === null || loadedMinutesItem.idClientLoadedMinutes < 1) && loadedMinutesItem.loadedMinutes === 0 && loadedMinutesItem.allocatedMinutes === 0  ) {
          projectItem.loadedMinutesList = projectItem.loadedMinutesList.filter(item => item !== loadedMinutesItem);
        }

        totalAllocatedMinutes += transformHoursToMinutes(loadedMinutesItem.allocatedMinutesHoursFormat);
        totalLoadedMinutes += transformHoursToMinutes(loadedMinutesItem.loadedMinutesHoursFormat);
      }

      if (totalAllocatedMinutes > projectItem.totalMinutes) {
        Swal.fire(
          "Erro!",
          "A soma das horas alocadas no projeto " + projectItem.name + " excede o total de horas do projeto.",
          "error"
        );
        allRight = false;
        break;
      }

      if (totalLoadedMinutes > projectItem.totalMinutes) {
        Swal.fire(
          "Erro!",
          "A soma das horas carregadas no projeto " + projectItem.name + " excede o total de horas do projeto.",
          "error"
        );
        allRight = false;
        break;
      }
    }

    //Aqui verificamos se a anotação para faturamento foi alterada, se sim, efetuamos o ajuste no banco. 
    if (noteForBillingLocal !== noteForBilling){
      changeNoteForBilling(noteForBillingLocal);
    }

    //aqui tenho que percorrer novamente a lista, e validar se ela possui algum registro de loadedminute e só seguir se existir. 
    let hasLoadedMinutesItem = false;
    for (const projectItem of projectListTmp) {
      for (const loadedMinutesItem of projectItem.loadedMinutesList) {

        if ((loadedMinutesItem.idClientLoadedMinutes === null || loadedMinutesItem.idClientLoadedMinutes < 1) && (loadedMinutesItem.AllocatedMinutes === 0 && loadedMinutesItem.loadedMinutes === 0)) {
          projectItem.loadedMinutesList = projectItem.loadedMinutesList.filter(item => item !== loadedMinutesItem);
        }
        hasLoadedMinutesItem = true;
        break; // aqui interrompe o loop
      }
    }

    if (!hasLoadedMinutesItem) {
      Swal.fire(
        "Aviso!",
        "Não há horas a serem Allocadas ou Carregadas.",
        "warning"
      );
      allRight = false;
    }

    
    if (allRight){
      const projectLoadMinutes = projectListTmp.map((plt) => {
        plt.totalLoadedMinutes = plt.loadedMinutesList.reduce((total, item) => total + item.loadedMinutes, 0);
        plt.startPeriod = startPeriod;
        plt.endPeriod = endPeriod;
        return plt
      }) 
      
      loadMinutes(projectLoadMinutes);
    }
    
  };

  const loadMinutes = async projectListTmp => {
    //Aqui Gravamos a lista dos carregamentos na tabela do Banco. 
    try {
      await api.makeHttpRequest({
        url: "/clientloadedminutes/load-minutes-flexi-closure",
        method: "POST",
        data: { Professional: {IdUser: user} , LoadMinutesProjectList: projectListTmp }
      });

      Swal.fire(
        "Sucesso!",
        "Sucesso ao atribuir Horas Carregadas e Código de Demanda.",
        "success"
      );
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao atribuir Horas Carregadas e Código de Demanda.",
        "error"
      );
    } 

    setProjectListLocal(projectListTmp);

    loadLoadedMinutesByProject(projectListTmp);

    setReload(true);
    close();
  };

  
  const changeNoteForBilling = async NewNoteForBilling => {

    try {
      await api.makeHttpRequest({
        url: `/professional/updateNoteForBilling/${user}`,
        method: "PUT",
        data: {
          NoteForBilling: NewNoteForBilling
        }
      });

      setNoteForBillingLocal(NewNoteForBilling);

    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao alterar Nota de Faturamento.",
        "error"
      );
    }

  };

  
  React.useEffect(() => {
    if (user > 0){
      setShowModal(true);
    }else{
      setShowModal(false);
    }
  }, [user]); 

  const handlePasteAllocatedHours = (e) => {
    handleAllocatedHoursChange(e)
  }

  const handlePasteLoadedMinutesHours = (e) => {
    handleLoadedHoursChange(e)
  }

  return (
    <>
      <Loading isLoading={loading} />
      <StyledModal show={showModal && !loading} onHide={() => close()} size="lg" centered  >
        <Modal.Body>
          <h1 className="modal-title">
            <strong>{title}</strong>
          </h1> 

          <Form>
            <Container>
              <Form.Group as={Col} lg="12" xs="12">
                <InputField
                  type="text"
                  name="noteForBillingLocal"
                  label="Obs"
                  placeholder="Digite a Observação"
                  value={noteForBillingLocal}
                  onChange={handleObservationChange}
                />
              </Form.Group>
              {/* Atividade do Profissional */}
              
              { projectListLocal?.length > 0 &&
                projectListLocal.map((projectItem, index) => (
                  <div key={projectItem.idProject+"_"+index}>

                    {index === 0 || projectItem.period !== projectListLocal[index - 1].period ? (
                      <Form.Row >
                        <Form.Group as={Col} lg="4" xs="12">
                          <Period>
                            <div>Período:  <strong>{moment(startPeriod).add(3, 'hour').format('DD/MM/YYYY')}</strong> à <strong>{moment(endPeriod).add(3, 'hour').format('DD/MM/YYYY')}</strong></div>
                          </Period>
                        </Form.Group>
                      </Form.Row>
                      
                    ) : null}
                      
                    <Form.Row >
                      <TitlePrj>
                        <div className="project">
                          <h2>Projeto: <strong>{projectItem.name.slice(0, 40)}</strong></h2>
                        </div>
                        <div className="time">
                          <h3>
                            H.A.: <strong style={{ 
                              color: projectItem.totalAllocatedMinutes < projectItem.totalMinutes 
                              ? '#ff504d' : 'inherit' 
                            }}>
                              {transformMinutesToHours(projectItem.totalAllocatedMinutes)}
                            </strong>&nbsp;
                            H.C.: <strong style={{ 
                              color: projectItem.totalLoadedMinutes < projectItem.totalMinutes 
                              ? '#ff504d' : 'inherit' 
                            }}>
                              {transformMinutesToHours(projectItem.totalLoadedMinutes)}
                            </strong>&nbsp;
                            T.H: <strong>{transformMinutesToHours(projectItem.totalMinutes)}</strong>
                            <StyledPlusIcon onClick={() => handleAddFieldsInProject(projectItem.idProject)} />
                          </h3>
                          
                        </div>
                      </TitlePrj>
                    </Form.Row>
                    {projectItem.loadedMinutesList?.length > 0 && 
                      projectItem.loadedMinutesList.map((loadedMinutesItem, index) => (
                      <>                        
                        <Form.Row key={index} >
                          <Fields>
                            <Form.Group as={Col} lg="2" xs="12">
                              <InputField
                                type="text"
                                name={"demand;;" + projectItem.idProject + ';;' + loadedMinutesItem.idx + ';;' + projectItem.period}
                                label="JIRA"
                                placeholder="Digite a Demanda"
                                value={loadedMinutesItem.demand}
                                onChange={handleDemandChange}
                              />
                            </Form.Group>

                            <Form.Group as={Col} lg="4" xs="12">
                              <InputField
                                type="text"
                                name={"payingArea;;" + projectItem.idProject + ';;' + loadedMinutesItem.idx + ';;' + projectItem.period }
                                label="Área Pagadora"
                                placeholder="Digite a Área Pagadora"
                                value={loadedMinutesItem.payingAreaName}
                                onChange={handlePayingAreaChange}
                              />
                            </Form.Group>

                            <Form.Group as={Col} lg="3" xs="12" style={{
                              display: "flex",
                              alignItems: "center"
                            }}>
                              <InputField
                                type="text"
                                name={"hAlocada;;" + projectItem.idProject + ';;' + loadedMinutesItem.idx + ';;' + projectItem.period }
                                label="H.Alocada"
                                placeholder="00:00:00"
                                value={loadedMinutesItem.allocatedMinutesHoursFormat}
                                onChange={handleAllocatedHoursChange}
                              />
                              <StyledPasteIcon onClick={() => handlePasteAllocatedHours({
                                target: {
                                  name: "hAlocada;;" + projectItem.idProject + ';;' + loadedMinutesItem.idx + ';;' + projectItem.period,
                                  value: transformMinutesToHours(projectItem.totalMinutes)
                                }
                              })} />
                            </Form.Group>

                            <Form.Group as={Col} lg="3" xs="12" style={{
                              display: "flex",
                              alignItems: "center"
                            }}>
                              <InputField
                                type="text"
                                name={"hCarregada;;" + projectItem.idProject + ';;' + loadedMinutesItem.idx + ';;' + projectItem.period }
                                label="H.Carregada"
                                placeholder="00:00:00"
                                value={loadedMinutesItem.loadedMinutesHoursFormat}
                                onChange={handleLoadedHoursChange}
                              />
                              <StyledPasteIcon onClick={() => handlePasteLoadedMinutesHours({
                                target: {
                                  name: "hCarregada;;" + projectItem.idProject + ';;' + loadedMinutesItem.idx + ';;' + projectItem.period,
                                  value: transformMinutesToHours(projectItem.totalMinutes)
                                }
                              })} />
                            </Form.Group>
                          </Fields>
                        </Form.Row>
                      </>
                      ))}
                    <hr></hr>
                    
                  </div>
                ))}
            </Container>
            
            <div className="btn-container">
              <Button type="button" onClick={close}>
                CANCELAR
              </Button>
              <Button
                type="button" //aqui se mudarmos para submit ao clicar ele irá dar reload na pagina. 
                onClick={e => {
                  handleModalConfirm();
                }}
              >
                CONFIRMAR
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  );
};
